import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'
import Hamburger from './Hamburger'
import styled, { css } from 'styled-components'
import theme, { makeGradient } from '../../config/theme'
import { graphql, Link, useStaticQuery } from 'gatsby';

const LinkUnderlined = css`
  ::after {
    position: relative;
    display: block;
    width: 100%;
    top: .2em;
    height: 1px;
    content: "";
    background-color: ${theme.colors.inverse};
    transition: all .2s;
    transform: scaleX(0);
  }

  &.active::after, :hover::after, :focus::after {
    transform: scaleX(1);
  }
`

const Title = styled.h1`
  display: inline;
  color: ${theme.colors.inverse};
  margin: 0 .5em;
  font-size: 1.5rem;
`

const Nav = styled.nav.attrs(({
  isOpen,
  className,
  ...props
}) => ({
  className: (className || '') + (isOpen ? ' open' : ''),
  ...props
}))`
  height: 100%;
  display: flex;
  align-items: center;

  @media not all and (min-width: 480px) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding: 0.5em 1em;
    /* TODO: max height */

    background: ${makeGradient(theme.colors.dark, '90deg')};

    & > * {
      margin: 1rem;
      margin-top: 0;
    }

    transition: clip-path .3s ease-in-out;
    transition-delay: 0;
    clip-path: polygon(0 0, 0 0, 105% 0, 105% 0);

    &.open {
      transition-delay: 0.1s; /* wait for potential header fade in */
      clip-path: polygon(0 0, 0 105%, 105% 105%, 105% 0);
    }
  }
`

const NavLinkInner = styled.h2`
  color: ${theme.colors.inverse};
  margin: 0;
  font-size: 1.1rem;
  font-weight: 400;
`

const _NavLink = ({children, className, ...props}) =>
  <Link getProps={({ isPartiallyCurrent }) => {
      return {className: className + (isPartiallyCurrent ? ' active' : '')}}
    }
    {...props}>
    <NavLinkInner>{children}</NavLinkInner>
  </Link>

_NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

const NavLink = styled(_NavLink)`
  margin-left: 1rem;
  text-decoration: none;

  ${LinkUnderlined}
`

const TitleLink = styled(Link)`
  text-decoration: none;

  ${LinkUnderlined}
`

const headerQuery = graphql`
  query Header {
    site {
      siteMetadata {
        siteTitle
      }
    }
  }
`

const headroomTransition = 'transform 200ms linear'

const StyledHeadroom = styled(Headroom).attrs(({
  fixed,
  className,
  ...props
}) => ({
  className: (className || '') + (fixed ? ' fixed' : ''),
  ...props
}))`
  width: 100%;
  z-index: 100;
  background-color: transparent;

  /*
   * No way to get smooth animation, so we have to do this instead
   */
  position: fixed;

  /*
   * Override calculated height because we do not want the header to reserve
   * space - it's already fixed
   */
  height: 0px !important;

  .headroom {
    position: fixed;
    width: 100%;

    height: ${theme.dimensions.headerHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;

    transition: ${headroomTransition};

    &::before { /* Background fill */
      position: absolute;
      display: block;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -100;

      background: ${makeGradient(theme.colors.dark, '90deg')};

      opacity: 1;
      transition: opacity 200ms linear;
    }
  }
  .headroom--unfixed {
    position: relative;

    &::before {
      opacity: 0;
    }
  }
  .headroom--scrolled {
    transform: translateY(0);
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0);
  }
  &.fixed .headroom {
    transform: translateY(0);
    &::before {
      opacity: 1;
    }
  }
`

const MenuHamburger = ({ className, ...props }) =>
  <button className={className} aria-label="Menu" aria-controls="navigation">
    <Hamburger {...props} />
  </button>


const StyledMenuHamburger = styled(MenuHamburger)`
  /* Normalize (<button>) */
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: visible;

  & > * {
    margin: 0.4rem;
  }

  @media (min-width: 480px) {
    display: none;
  }
`

const Header = ({...props}) => {
  const [isOpen, setIsOpen] = useState(false)
  const data = useStaticQuery(headerQuery)
  const NL = (props) => <NavLink onClick={() => setIsOpen(false)} {...props} />
  return (
    <StyledHeadroom disableInlineStyles {...props} fixed={isOpen}>
      <TitleLink to='/'
        onClick={(ev) => {ev.target.blur(); return true}}
      >
        <Title>{data.site.siteMetadata.siteTitle}</Title>
      </TitleLink>
      <StyledMenuHamburger
        color={theme.colors.light.color}
        isActive={isOpen} onClick={() => setIsOpen(!isOpen)}
      />
      <Nav id="navigation" isOpen={isOpen}>
        <NL to='/projects/'>Projects</NL>
        <NL to='/presentations/'>Presentations</NL>
        <NL to='/blog/'>Blog</NL>
      </Nav>
    </StyledHeadroom>
  )
}

export default Header
