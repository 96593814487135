import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import theme, { makeGradient } from '../../config/theme'
import { FaGithub, FaGitlab } from 'react-icons/fa'
import { Link } from 'gatsby'

const paddingSize = '2rem';
const angleSize = '3rem';

const Wrapper = styled.div`
  margin-top: calc(0px - (${angleSize} / 2));
  padding: ${paddingSize};
  padding-top: calc(${paddingSize} + ${angleSize});
  clip-path: polygon(0 0, 100% ${angleSize}, 100% 100%, 0 100%);

  background: ${makeGradient(theme.colors.dark, '135deg')};
  color: ${theme.colors.light.muted.color};

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: .75rem;
  }
`

const _LinksWrapper = styled.div`
  & > * {
    opacity: 0.8;
  }
`

const LinksSpacer = styled.span.attrs(props => ({
  children: '|',
  ...props
}))`
  display: inline-block;
  margin: 0 0.5em;
`

const LinksWrapper = ({ children, ...props }) => {
  children = React.Children.toArray(children)
  return (
    <_LinksWrapper {...props}>
      {children.slice(0,-1).map(e =>
        <>
          {e}
          <LinksSpacer/>
        </>
      )}
      {children.slice(-1)}
    </_LinksWrapper>
  )
}

const linkCommonStyles = css`
  color: inherit;
  text-decoration: none;

  opacity: 0.8;
  transition: opacity 200ms ease-in-out;

  :hover {
    opacity: 1;
  }
`

const FooterLink = styled(Link)`
  ${linkCommonStyles}
`

const IconLink = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener',
}))`
  ${linkCommonStyles}
  margin-left: .5em;
`

const Footer = ({...props}) =>
  <Wrapper {...props}>
    <h3>Ethan Wu</h3>
    <LinksWrapper>
      <span key="LogosSpan">
        Find me @ethanwu10
        <IconLink href='https://gitlab.com/ethanwu10' aria-label="GitLab"><FaGitlab/></IconLink>
        <IconLink href='https://github.com/ethanwu10' aria-label="GitHub"><FaGithub/></IconLink>
      </span>
    </LinksWrapper>
  </Wrapper>

export default Footer
