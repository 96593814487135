const fallbackFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
]

const headerFonts = [
  'Montserrat',
  ...fallbackFonts
]
const bodyFonts = [
  'Lato',
  ...fallbackFonts
]

const typography = {
  headerFonts,
  bodyFonts,
  baseSize: '18px',
  lineHeight: 1.5,
}

const colors = {
  primary: {
    color: '#406aa3',
    gradientA: '#4587c5',
    gradientB: '#3b4b7e',
  },
  secondary: {
    color: '#348835',
    gradientA: '#69a05f',
    gradientB: '#05730f',
  },
  light: {
    color: '#fff',
    gradientA: '#fff',
    gradientB: '#fff',
    muted: {
      color: 'hsla(0,0%,100%,0.75)',
      gradientA: 'hsla(0,0%,100%,0.75)',
      gradientB: 'hsla(0,0%,100%,0.65)',
    }
  },
  dark: {
    color: '#22282e',
    gradientA: '#353538',
    gradientB: '#0c1823',
  },
  inverse: '#eeeeee',
}

const dimensions = {
  headerHeight: '4rem'
}

const theme = {
  colors,
  dimensions,
  typography,
}

const makeGradient = (swatch, angle) =>
  `linear-gradient(${angle}, ${swatch.gradientA}, ${swatch.gradientB})`

module.exports = {
  ...theme,
  makeGradient
}
