import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme, {makeGradient} from '../../config/theme'

const Hero = styled.div.attrs(({padding, angleSize, big}) => ({
  padding: padding || '2rem',
  angleSize: angleSize || '3rem',
  big: big || false
}))`
  width: 100%;
  min-height: ${props => props.big ? '75vh' : '20rem'};
  padding: ${props => props.padding};
  padding-bottom: calc(${props => props.padding} + ${props => props.angleSize});
  padding-top: calc(${props => props.padding} + ${theme.dimensions.headerHeight});
  margin-bottom: calc(0px - (${props => props.angleSize} / 2));

  background: ${props => props.background || makeGradient(theme.colors.primary, '135deg')};
  color: ${props => props.color || theme.colors.inverse};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* font-size: 125%; */ /* Typography.js uses rems :( */
  h1 {
    font-size: 2.5rem;
  }

  clip-path: polygon(0 100%, 100% calc(100% - 3rem), 100% 0, 0 0);

  z-index: -10;

  & > * {
    text-align: center;
  }
`

Hero.propTypes = {
  background: PropTypes.shape({
    gradientA: PropTypes.string.isRequired,
    gradientB: PropTypes.string.isRequired
  }),
  color: PropTypes.string,
  padding: PropTypes.string,
  angleSize: PropTypes.string,
  big: PropTypes.bool
}

export default Hero
