import React from 'react'
import PropTypes from 'prop-types'
import SEO from './SEO'
import Header from './Header'
import 'typeface-lato'
import 'typeface-montserrat'
import styled, { createGlobalStyle } from 'styled-components'
import Footer from './Footer'
// import theme from '../../config/theme'
import '../css/prism-theme-oceanicnext.css'

const GlobalStyle = createGlobalStyle`
  html {
    overflow-x: hidden;
  }

  a:focus {
    outline: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const ContentWrapper = styled.div`
  flex: 1;
`

const StickyFooter = styled(Footer)`
  flex: 0;
`

const Layout = ({manualFlex, children}) =>
  <>
    <GlobalStyle/>
    <SEO/>
    <Wrapper>
      <Header/>
      {manualFlex ? children : <ContentWrapper>{children}</ContentWrapper>}
      <StickyFooter/>
    </Wrapper>
  </>

Layout.propTypes = {
  manualFlex: PropTypes.bool,
}

Layout.defaultProps = {
  manualFlex: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
