import React from 'react'
import styled from 'styled-components'

// Mostly derived from https://github.com/jonsuh/hamburgers, but React

const BAR_THICKNESS = '10%'
const NOMINAL_HEIGHT = '0.6em'

const Hamburger = styled.div.attrs(({
  color,
  size,
  cursor,
  isActive,
  className,
  ...props
}) => ({
  color: color || 'white',
  size: size || '2rem',
  cursor: cursor || 'pointer',
  className: (className || '') + (isActive ? ' active':''),
  children: [...new Array(3).keys()].map(i => <div key={i}/>),
  ...props
}))`
  font-size: ${({ size }) => size};
  cursor: ${({ cursor }) => cursor};

  /* Normalize (<button>) */
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: visible;

  height: ${NOMINAL_HEIGHT};
  width: 1em;

  position: relative;

  transition: opacity 0.3s;

  :hover {
    opacity: 0.8;
  }

  & > div {
    position: absolute;
    width: 100%;
    height: ${BAR_THICKNESS};
    background-color: ${({ color }) => color};
    border-radius: 999em;

    transition: opacity 0.1s, transform 0.1s ease;
    transform-origin: center center;
  }
  & > div:nth-child(1) {
    top: 0;
    .active& {
      transform: translateY(calc(${NOMINAL_HEIGHT} / 2 - 50%)) rotate(45deg);
    }
  }
  & > div:nth-child(2) {
    top: calc(50% - ${BAR_THICKNESS} / 2);
    .active& {
      opacity: 0;
      transform: translateX(-0.5em);
    }
  }
  & > div:nth-child(3) {
    bottom: 0;
    .active& {
      transform: translateY(calc(-1*(${NOMINAL_HEIGHT} / 2 - 50%))) rotate(-45deg);
    }
  }
`

export default Hamburger
